import { useLazyQuery, useMutation } from '@apollo/client';
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import { SupplierType } from '_graphql/__generated__/globalTypes';
import { useAppNaviagte, useCurrentUser } from '_graphql/cache/auth';
import {
  StripeAccountSession,
  StripeAccountSessionVariables,
} from '_graphql/mutation/__generated__/StripeAccountSession';
import {
  UpdateSupplierPayment,
  UpdateSupplierPaymentVariables,
} from '_graphql/mutation/__generated__/UpdateSupplierPayment';
import {
  stripeAccountSession,
  stripeExternalAccount,
} from '_graphql/mutation/integration';
import { updateSupplierPaymentDetails } from '_graphql/mutation/payment';
import {
  PaystackResolveAccountNumber,
  PaystackResolveAccountNumberVariables,
} from '_graphql/queries/__generated__/PaystackResolveAccountNumber';
import { resolvePaymentMethod } from '_graphql/queries/suppliers';
import { Modal, OfficeButton, toast } from 'components';
import { useFormik } from 'formik';
import { useSupplier } from 'pages/settings/hooks/use-supplier';
import { FC, useEffect, useMemo, useState } from 'react';
import { IPaymentMethod } from './create-payment-method';
import PaymentDetailsForm from './forms/payment-details';
import {
  StripeExternalBankAccount,
  StripeExternalBankAccountVariables,
} from '_graphql/mutation/__generated__/StripeExternalBankAccount';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const UpdatePaymentDetailsContainer: FC<Props> = ({ setOpen, open }) => {
  const currentUser = useCurrentUser();
  const { supplier, loading: fetching } = useSupplier({
    variables: {
      filter: {
        id: {
          eq: currentUser?.id,
        },
      },
    },
  });
  const CAN_USE_STRIPE = useMemo(
    () =>
      !['GH', 'NG'].includes(currentUser?.country as string) &&
      currentUser.supplier_type === SupplierType.ADVANCED,
    [currentUser]
  );
  const navigate = useAppNaviagte();
  const [resolve, { loading }] = useLazyQuery<
    PaystackResolveAccountNumber,
    PaystackResolveAccountNumberVariables
  >(resolvePaymentMethod);
  const [create, { loading: creating }] = useMutation<
    UpdateSupplierPayment,
    UpdateSupplierPaymentVariables
  >(updateSupplierPaymentDetails, {
    refetchQueries: ['GetSupplier'],
  });
  const [session, { loading: waiting }] = useMutation<
    StripeAccountSession,
    StripeAccountSessionVariables
  >(stripeAccountSession);
  const [ext] = useMutation<
    StripeExternalBankAccount,
    StripeExternalBankAccountVariables
  >(stripeExternalAccount, {
    refetchQueries: ['GetSupplier'],
  });

  const onLoadError = (loadError: any) => {
    const componentName = loadError.elementTagName;
    const error = loadError.error;
    console.log(componentName + ' failed to load');
    console.log(`${error.type}: ${error.message}`);
  };

  const [stripeConnectInstance] = useState(
    CAN_USE_STRIPE
      ? () => {
          const fetchClientSecret = async () => {
            // Fetch the AccountSession client secret
            const { data } = await session({
              variables: {
                input: {
                  country: currentUser?.country,
                  email: currentUser?.email,
                  supplier_id: currentUser.id,
                  business_data: supplier?.business_details
                    ? JSON.stringify(supplier?.business_details)
                    : undefined,
                },
              },
              onError(error) {
                toast(error.message, 'error');
              },
            });

            return data?.stripeAccountSession as string;
          };

          return loadConnectAndInitialize({
            // This is your test publishable API key.
            publishableKey: process.env
              .REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
            fetchClientSecret: fetchClientSecret,
          });
        }
      : undefined
  );

  const form = useFormik<IPaymentMethod>({
    initialValues: {
      provider: '',
      account_number: '',
      account_name: '',
    },
    onSubmit: (values) => {
      create({
        variables: {
          input: {
            input: {
              account_name: values.account_name,
              account_number: values.account_number,
              provider: values.provider?.code,
              method: '',
            },
            supplier_id: currentUser.id,
          },
        },
      }).then((res) => {
        setOpen(false);
        if (res.data?.updateSupplierPayment?.id) {
          navigate({
            search: (old) => ({
              ...old,
              modal: undefined,
              id: undefined,
            }),
          });
          toast('Payment details updated successfully', 'success');
        } else {
          toast('An error occurred', 'error');
        }
      });
    },
  });

  const handleValidation = async () => {
    form.setFieldValue('account_name', '');
    if (form.values.provider && form.values.account_number) {
      resolve({
        variables: {
          data: {
            bank_code: form.values.provider?.code,
            account_number: form.values.account_number,
          },
        },
      })
        .then((res) => {
          if (res.data?.paystackResolveAccountNumber.status) {
            form.setFieldValue(
              'account_name',
              res.data.paystackResolveAccountNumber.data.account_name
            );
          } else {
            toast('Invalid account number', 'error');
          }
        })
        .catch((err) => {
          toast('Something wrong happened. Try again', 'error');
        });
    }
  };

  useEffect(() => {
    if (supplier?.payment_details) {
      form.setValues({
        provider: supplier.payment_details.provider || '',
        account_number: supplier.payment_details.account_number || '',
        account_name: supplier.payment_details.account_name || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier, fetching]);

  return (
    <Modal
      open={open}
      loading={fetching || waiting}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      title="Payment Details"
      description="View your payment details."
      renderActions={
        !CAN_USE_STRIPE
          ? () => (
              <OfficeButton ml onClick={form.handleSubmit}>
                {creating ? 'Adding details ...' : 'Add details'}
              </OfficeButton>
            )
          : undefined
      }
    >
      {!CAN_USE_STRIPE && (
        <PaymentDetailsForm
          form={form}
          resolving={loading}
          handleOnBlur={handleValidation}
        />
      )}

      {CAN_USE_STRIPE && (
        <ConnectComponentsProvider
          connectInstance={stripeConnectInstance as StripeConnectInstance}
        >
          <ConnectAccountOnboarding
            onLoadError={onLoadError}
            onExit={async () => {
              await ext({
                variables: {
                  supplierId: currentUser.id,
                },
                onError(error) {
                  console.log(error.message);
                },
                onCompleted(data) {
                  navigate({
                    search: (old) => ({
                      ...old,
                      modal: undefined,
                      id: undefined,
                    }),
                  });
                  toast('Payment details updated successfully', 'success');
                },
              });
              console.log('existed');
            }}
          />
        </ConnectComponentsProvider>
      )}
    </Modal>
  );
};

export default UpdatePaymentDetailsContainer;
