import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MediaServices from '../services/media.service';

export type IDigiFile = {
  id: number | undefined;
  loading: boolean;
  error: string;
  imageLink: string;
  name: string;
  path: string;
  type: string;
  thumbnailLink: string;
  progress?: string;
};

interface UploadProgressResponse {
  progress?: string;
  success?: boolean;
  data?: Array<{
    id: number | undefined;
    name: string;
    path: string;
    imageLink: string;
    thumbnailLink: string;
    type: string;
  }>;
  msg?: string;
}

type UploadProps = {
  multiple?: boolean;
  onUploaded?: (images: IDigiFile[]) => void;
};

const useUploadFile = (title?: string, props?: UploadProps) => {
  const [uploadingFile, setUploadingFile] = useState<IDigiFile[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file: File) => {
        // Initialize the file upload with 0% progress
        setUploadingFile((prevState) =>
          prevState.concat({
            id: undefined,
            loading: true,
            error: '',
            imageLink: '',
            name: file.name,
            path: '',
            type: '',
            thumbnailLink: '',
            progress: '0%',
          })
        );

        const formData = new FormData();
        formData.append('media', file);
        formData.append('folder', `tendo/${title ? title.replace(' ', '-') : 'product-file'}`);
        formData.append('origin', 'supplier-dashboard');

        // Make request using XMLHttpRequest to process streamed response
        const xhr = new XMLHttpRequest();

        xhr.open('POST', 'https://media.tendo.africa/api/v1/soko/media/upload', true);

        // Monitor progress from the server
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.LOADING || xhr.readyState === XMLHttpRequest.DONE) {
            const responseText = xhr.responseText;
            const lines = responseText.split('\n').filter((line) => line.trim() !== '');

            lines.forEach((line) => {
              try {
                const parsedData: UploadProgressResponse = JSON.parse(line);

                // Handle progress update
                if (parsedData.progress) {
                  setUploadingFile((prev) => {
                    const index = prev.findIndex((el) => el.name === file.name);
                    const newImages = prev.map((item, idx) =>
                      idx === index
                        ? { ...item, progress: parsedData.progress }
                        : item
                    );
                    props?.onUploaded && props?.onUploaded(newImages); // Call onUploaded if available
                    return newImages;
                  });
                }

                // Handle final success
                if (parsedData.success) {
                  setUploadingFile((prev) => {
                    const index = prev.findIndex((el) => el.name === file.name);
                    const newImages = prev.map((item, idx) =>
                      idx === index
                        ? {
                            ...item,
                            loading: false,
                            error: '',
                            imageLink: parsedData?.data?.[0]?.imageLink || '',
                            path: parsedData?.data?.[0]?.path || '',
                            id: parsedData?.data?.[0]?.id,
                            thumbnailLink: parsedData?.data?.[0]?.thumbnailLink || '',
                            type: parsedData?.data?.[0]?.type || '',
                          }
                        : item
                    );
                    props?.onUploaded && props?.onUploaded(newImages); // Trigger final upload state update
                    return newImages;
                  });
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            });
          }
        };

        xhr.onerror = () => {
          setUploadingFile((prevState) =>
            prevState.map((item) =>
              item.name === file.name
                ? {
                    ...item,
                    loading: false,
                    error: 'Upload failed',
                  }
                : item
            )
          );
        };

        xhr.send(formData); // Start the upload with FormData
      });
    },
    [title, props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/zip': ['.zip'],
      'applicaton/docx': ['.docx'],
      'video/mp4': ['.mp4'],
      'audio/mpeg': ['.mp3'],
    },
    multiple: props?.multiple,
  });

  const deleteFile = (index: number) => {
    const temp = uploadingFile[index];
    // const file = {
    //   id: temp.id,
    //   imageLink: temp.imageLink,
    //   name: temp.name,
    //   path: temp.path,
    //   type: temp.type,
    //   thumbnailLink: temp.thumbnailLink,
    // };
    if (temp.id) {
      MediaServices.deleteMedia(temp.path)
        .then(() => {
          setUploadingFile((prevState) =>
            prevState.filter((item, i) => i !== index)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUploadingFile((prevState) =>
        prevState.filter((item, i) => i !== index)
      );
    }
  };

  const onUrlInputSubmit = (e: Record<string, any>, callback: Function) => {
    const mediaUrlInputArray = Object.values(e).map((obj) => ({
      url: obj,
      folder: `tendo/${title ? title.replace(/\s+/g, '-').toLowerCase() : 'product-file'}`,
      origin: 'supplier-dashboard',
    }));

    mediaUrlInputArray.forEach(async (mediaUrlInput) => {
      setUploadingFile((prevState) =>
        prevState.concat({
          id: undefined,
          loading: true,
          error: '',
          imageLink: '',
          name: mediaUrlInput.url,
          path: '',
          type: '',
          thumbnailLink: '',
        })
      );
      MediaServices.uploadMediaUsingUrl(mediaUrlInput)
        .then((res) => {
          setUploadingFile((prevState) => {
            const index = prevState.findIndex(
              (el) => el.name === mediaUrlInput.url
            );
            const newImages = R.adjust(
              index,
              (item) => ({
                ...item,
                loading: false,
                error: '',
                imageLink: res?.data?.data?.imageLink,
                path: res?.data?.data?.path,
                id: res?.data?.data?.id,
                name: res?.data?.data?.originalname,
                thumbnailLink: res?.data?.data?.thumbnailLink,
                type: res?.data?.data?.type,
              }),
              prevState
            );

            props?.onUploaded && props?.onUploaded(newImages);
            return newImages;
          });
        })
        .catch((err) => {
          setUploadingFile((prevState) =>
            prevState.map((item) =>
              item.name === mediaUrlInput.url
                ? {
                    ...item,
                    loading: false,
                    error: err.message,
                  }
                : item
            )
          );
        });
    });
    callback();
  };

  return {
    onUrlInputSubmit,
    uploadingFile,
    getRootProps,
    getInputProps,
    isDragActive,
    deleteFile,
  };
};

export default useUploadFile;
