import { OfficeHeader, toast } from 'components';
import { FC, useState } from 'react';
import { useUrlState } from 'utils';
import ConnectShop from './components/connect-shop';

import Productssync from './components/products-sync';
import { useShopifyShop } from './hooks/useShopifyShop';
import { useMutation, useReactiveVar } from '@apollo/client';
import { currentUserVar } from '_graphql/cache/auth';
import PaymentMethod from './components/payment-method';
import { removeShopifyShop } from '_graphql/mutation/integration';
import {
  RemoveShopifyShop,
  RemoveShopifyShopVariables,
} from '_graphql/mutation/__generated__/RemoveShopifyShop';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import check from '../../assets/checkcircle.svg';
import radio from '../../assets/radio.svg';

type Props = {};

const ShopifyOverview: FC<Props> = (props) => {
  const currentUser = useReactiveVar(currentUserVar);
  const [modal, setModal] = useUrlState('modal');
  const [setupIntent] = useUrlState('setup_intent');
  const stripePromise = loadStripe(config.stripe.key);
  const [addPayment, setAddPayment] = useState(false);
  const [showSync, setShowSync] = useState(false);

  const { shopData } = useShopifyShop({
    input: {
      filter: {
        supplier_id: {
          eq: currentUser?.id,
        },
      },
    },
  });

  const [remove, { loading }] = useMutation<
    RemoveShopifyShop,
    RemoveShopifyShopVariables
  >(removeShopifyShop, {
    refetchQueries: ['GetShopifyShop'],
  });

  const removeShop = () => {
    remove({
      variables: {
        supplierId: currentUser?.id,
      },
    })
      .then((res) => {
        if (res.data?.removeShopifyShop) {
          toast('Shopify shop removed successfully', 'success');
        } else {
          toast('Something went wrong', 'error');
        }
      })
      .catch((err) => {
        toast(err.message, 'error');
      });
  };

  // if (isLoading) return <Loader />

  return (
    <main className="flex-1 flex flex-col overflow-y-scroll h-screen bg-gray-50 mb-10">
      <OfficeHeader />
      <div className="md:px-10 px-5 ">
        <div className="flex flex-col items-center justify-center py-5 gap-2">
          <h2 className="text-3xl font-semibold text-gray-700">
            Let's get you started
          </h2>
          <span className="text-gray-500 text-sm">
            Complete the following steps to start doing business with Duke.
          </span>
        </div>

        <div className="grid gap-6 max-w-5xl mx-auto">
          <div className="flex p-4 bg-white border border-gray-300 shadow-sm rounded-lg gap-2 ">
            {shopData?.id ? (
              <img src={check} className="h-6 w-6 mt-1" alt="" />
            ) : (
              <img src={radio} className="h-5 w-5 mt-1" alt="" />
            )}
            <div className="flex justify-between w-full">
              <div className="grid ">
                <span className="text-lg font-medium mb-2 flex gap-2 items-center text-gray-700">
                  Connect shopify store
                </span>
                {!shopData?.id && (
                  <span className="text-gray-500 text-sm">
                    Please connect the DukeSell Shopify app to your site. More
                    info{' '}
                  </span>
                )}
                {shopData?.shop && (
                  <span className="text-sm text-gray-500">
                    Store:
                    <span className="font-semibold"> {shopData?.shop}</span>
                  </span>
                )}
              </div>
              <div>
                {shopData?.id ? (
                  <button
                    onClick={removeShop}
                    className="bg-[#FF796F] whitespace-nowrap text-white text-sm px-4 py-1 rounded-md"
                  >
                    {loading ? 'please wait...' : 'Remove'}
                  </button>
                ) : (
                  <button
                    onClick={() => setModal('connect')}
                    className="bg-[#FF796F] whitespace-nowrap text-white text-sm px-4 py-1 rounded-md"
                  >
                    Connect
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className=" p-4 bg-white border border-gray-300 shadow-sm rounded-lg gap-2 grid">
            <div className="flex gap-2">
              {shopData?.shop_payment_method ? (
                <img src={check} className="h-6 w-6 mt-1" alt="" />
              ) : (
                <img src={radio} className="h-5 w-5 mt-1" alt="" />
              )}
              <div className="flex justify-between gap-5 w-full">
                <div className="grid ">
                  <span className="text-lg font-medium mb-2 flex gap-2 items-center text-gray-700">
                    {' '}
                    {!shopData?.shop_payment_method
                      ? 'Add a payment method'
                      : 'Payment method'}
                  </span>
                  {!shopData?.shop_payment_method ? (
                    <span className="text-gray-500 text-sm">
                      Please add your payment (credit card) . This card will
                      receive the charges for both you Duke platform fee as well
                      as for your seller’s invoice.{' '}
                    </span>
                  ) : (
                    shopData?.shop_payment_method?.secure_code && (
                      <div className="flex items-center gap-1">
                        <CreditCardIcon className="h-5 w-5 text-gray-600" />
                        <span className="text-gray-500 text-sm">
                          <span className="font-medium capitalize">
                            {shopData?.shop_payment_method?.provider}
                          </span>{' '}
                          ending in{' '}
                          <span className="font-medium">
                            {shopData?.shop_payment_method?.secure_code}
                          </span>
                        </span>
                      </div>
                    )
                  )}
                </div>
                {!addPayment && (
                  <div>
                    <button
                      onClick={() => setAddPayment(true)}
                      className="bg-[#FF796F] whitespace-nowrap text-white text-sm px-4 py-1 rounded-md"
                    >
                      {setupIntent
                        ? 'adding...'
                        : !shopData?.shop_payment_method
                          ? 'Add Payment'
                          : 'Change'}{' '}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {addPayment && (
              <Elements
                stripe={stripePromise}
                options={{ mode: 'setup', currency: 'usd' }}
              >
                <PaymentMethod integrationID={shopData?.id} />
              </Elements>
            )}
          </div>

          <div className="p-4 bg-white border border-gray-300 shadow-sm rounded-lg grid">
            <div className="flex justify-between   gap-3">
              <div className="grid ">
                <span className="text-lg font-medium mb-2 flex gap-2 items-center text-gray-700">
                  Select and sync products
                </span>
                <span className="text-gray-500 text-sm">
                  Choose items from your Shopify store and synchronize them for
                  display on a designated distribution channel.
                </span>
                <div>
                  {shopData?.id && (
                    <span className="text-sm bg-green-100 text-green-600 px-2 rounded-md">
                      {' '}
                      <span className="font-semibold">
                        {shopData?.products?.length}
                      </span>{' '}
                      products already synced
                    </span>
                  )}
                </div>
              </div>
              <div>
                {!showSync && (
                  <button
                    disabled={!shopData?.id}
                    onClick={() => setShowSync(true)}
                    className="bg-[#FF796F] whitespace-nowrap text-white text-sm px-4 py-1 rounded-md "
                  >
                    Sync products
                  </button>
                )}
              </div>
            </div>
            {showSync && (
              <Productssync
                syncedProducts={shopData?.products}
                integrationId={shopData?.id}
              />
            )}
          </div>
        </div>
      </div>
      <ConnectShop
        open={modal === 'connect'}
        setOpen={(val: boolean) => setModal(val ? 'connect' : undefined)}
      />
    </main>
  );
};

export default ShopifyOverview;
