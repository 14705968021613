import { useLazyQuery, useMutation } from '@apollo/client';
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import { SupplierType } from '_graphql/__generated__/globalTypes';
import {
  setSupplierSetupConfig,
  useAppNaviagte,
  useCurrentUser,
} from '_graphql/cache/auth';
import {
  AddSupplierPaymentDetails,
  AddSupplierPaymentDetailsVariables,
} from '_graphql/mutation/__generated__/AddSupplierPaymentDetails';
import {
  StripeAccountSession,
  StripeAccountSessionVariables,
} from '_graphql/mutation/__generated__/StripeAccountSession';
import {
  stripeAccountSession,
  stripeExternalAccount,
} from '_graphql/mutation/integration';
import { addSupplierPaymentDetails } from '_graphql/mutation/payment';
import {
  PaystackResolveAccountNumber,
  PaystackResolveAccountNumberVariables,
} from '_graphql/queries/__generated__/PaystackResolveAccountNumber';
import { resolvePaymentMethod } from '_graphql/queries/suppliers';
import { Modal, OfficeButton, toast } from 'components';
import { useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import PaymentDetailsForm from './forms/payment-details';
import {
  StripeExternalBankAccount,
  StripeExternalBankAccountVariables,
} from '_graphql/mutation/__generated__/StripeExternalBankAccount';
import _ from 'lodash';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

export type IPaymentMethod = {
  provider: any;
  account_name: string;
  account_number: string;
};

const CreatePaymentDetailsContainer: FC<Props> = ({ setOpen, open }) => {
  const currentUser = useCurrentUser();
  const CAN_USE_STRIPE = useMemo(
    () =>
      !['GH', 'NG'].includes(currentUser?.country as string) &&
      currentUser.supplier_type === SupplierType.ADVANCED,
    [currentUser]
  );
  const navigate = useAppNaviagte();
  const [resolve, { loading }] = useLazyQuery<
    PaystackResolveAccountNumber,
    PaystackResolveAccountNumberVariables
  >(resolvePaymentMethod);
  const [create, { loading: creating }] = useMutation<
    AddSupplierPaymentDetails,
    AddSupplierPaymentDetailsVariables
  >(addSupplierPaymentDetails, {
    refetchQueries: ['GetSupplier'],
  });
  const [session, { loading: waiting }] = useMutation<
    StripeAccountSession,
    StripeAccountSessionVariables
  >(stripeAccountSession);
  const [ext] = useMutation<
    StripeExternalBankAccount,
    StripeExternalBankAccountVariables
  >(stripeExternalAccount, {
    refetchQueries: ['GetSupplier'],
  });

  const onLoadError = (loadError: any) => {
    const componentName = loadError.elementTagName;
    const error = loadError.error;
    console.log(componentName + ' failed to load');
    console.log(`${error.type}: ${error.message}`);
  };

  const [stripeConnectInstance] = useState(
    CAN_USE_STRIPE
      ? () => {
          const fetchClientSecret = async () => {
            // Fetch the AccountSession client secret
            const { data } = await session({
              variables: {
                input: {
                  country: currentUser?.country,
                  email: currentUser?.email,
                  supplier_id: currentUser.id,
                  business_data: JSON.stringify(
                    _.omit(currentUser?.business_details, '__typename')
                  ),
                },
              },
              onError(error) {
                toast(error.message, 'error');
              },
            });

            return data?.stripeAccountSession as string;
          };

          return loadConnectAndInitialize({
            // This is your test publishable API key.
            publishableKey: process.env
              .REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
            fetchClientSecret: fetchClientSecret,
          });
        }
      : undefined
  );

  const form = useFormik<IPaymentMethod>({
    initialValues: {
      provider: null,
      account_number: '',
      account_name: '',
    },
    onSubmit: (values) => {
      create({
        variables: {
          input: {
            account_name: values.account_name,
            account_number: values.account_number,
            provider: values.provider?.code,
            supplier_id: currentUser.id,
            method:
              values.provider?.code === 'mobile_money'
                ? 'mobile_money'
                : 'bank',
          },
        },
      })
        .then((res) => {
          setOpen(false);
          if (res.data?.addSupplierPaymentDetails) {
            navigate({
              search: (old) => ({
                ...old,
                modal: undefined,
                id: undefined,
              }),
            });
            toast('Payment details added successfully', 'success');
            setSupplierSetupConfig({
              hasPaymentMethod: true,
            });
          } else {
            toast('Failed to add payment details', 'error');
          }
        })
        .catch((err) => {
          console.log(err);
          toast('Failed to add payment details', 'error');
        });
    },
  });

  const handleValidation = async () => {
    form.setFieldValue('account_name', '');
    if (form.values.provider && form.values.account_number) {
      resolve({
        variables: {
          data: {
            bank_code: form.values.provider?.code,
            account_number: form.values.account_number,
          },
        },
      })
        .then((res) => {
          if (res.data?.paystackResolveAccountNumber.status) {
            form.setFieldValue(
              'account_name',
              res.data.paystackResolveAccountNumber.data.account_name
            );
          } else {
            toast('Invalid account number', 'error');
          }
        })
        .catch((err) => {
          toast('Something wrong happened. Try again', 'error');
        });
    }
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      title="Payment Details"
      description="View your payment details."
      renderActions={
        !CAN_USE_STRIPE
          ? () => (
              <OfficeButton ml onClick={form.handleSubmit}>
                {creating ? 'Adding details ...' : 'Add details'}
              </OfficeButton>
            )
          : undefined
      }
      loading={waiting}
    >
      {!CAN_USE_STRIPE && (
        <PaymentDetailsForm
          form={form}
          resolving={loading}
          handleOnBlur={handleValidation}
        />
      )}

      {CAN_USE_STRIPE && (
        <ConnectComponentsProvider
          connectInstance={stripeConnectInstance as StripeConnectInstance}
        >
          <ConnectAccountOnboarding
            onLoadError={onLoadError}
            onExit={async () => {
              await ext({
                variables: {
                  supplierId: currentUser.id,
                },
                onError(error) {
                  console.log(error.message);
                },
                onCompleted(data) {
                  navigate({
                    search: (old) => ({
                      ...old,
                      modal: undefined,
                      id: undefined,
                    }),
                  });
                  toast('Payment details added successfully', 'success');
                  setSupplierSetupConfig({
                    hasPaymentMethod: true,
                  });
                },
              });
              console.log('existed');
            }}
          />
        </ConnectComponentsProvider>
      )}
    </Modal>
  );
};

export default CreatePaymentDetailsContainer;
