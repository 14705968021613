import { Modal, OfficeButton } from 'components';
import EditShopModal from './components/EditShopModal';
import { useShop } from './hooks/use-shop';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useRef } from 'react';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
};

const EditShopContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();

  const { shop, loading, refetch } = useShop({
    filter: {
      id: seacrhParams.id
        ? {
            eq: seacrhParams.id,
          }
        : undefined,
    },
  });

  const editShopModalRef = useRef<{ handleSubmit: () => void } | null>(null);

  const handleEditClick = () => {
    editShopModalRef.current?.handleSubmit();
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title="Edit Shop"
      description="Edit Shop Details"
      renderActions={() => (
        <button
          type="button"
          className="mt-3 w-full  inline-flex justify-center rounded-md border border-gray-300  shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white   hover: focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleEditClick}
        >
          Edit
        </button>
      )}
    >
      <EditShopModal ref={editShopModalRef} setOpen={setOpen} shop={shop} />
    </Modal>
  );
};

export default EditShopContainer;
