import { Modal } from 'components';
import _ from 'lodash';
import ProductDetails from './ProductDetails';
import { useProduct } from 'pages/products/hooks/use-products';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
type Props = {
  open: boolean;
  setOpen: (val: any) => void;
  currentProductID: string | null;
  // handleViewProductDetails: ()=>void;
};

const ViewProductContainer = ({ open, setOpen, currentProductID }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { product, loading, refetch } = useProduct({
    variables: {
      filter: {
        id: currentProductID
          ? {
              eq: currentProductID,
            }
          : undefined,
      },
    },
  });
  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      size="7xl"
      title="View Product Details"
      loading={loading}
      description=""
    >
      <ProductDetails product={product} />
    </Modal>
  );
};

export default ViewProductContainer;
