import { ActionButton, OfficeHeader, Paginator, TableComponent, TableLoader } from 'components'
import { FC } from 'react'
import { useCarts } from './hooks/use-carts'
import { useReactiveVar } from '@apollo/client'
import { currentUserVar, useAppSearch } from '_graphql/cache/auth'
import { usePagination } from 'hooks'
import { GetCarts_rows } from '_graphql/queries/__generated__/GetCarts'
import moment from 'moment'
import { useNavigate } from 'react-location'
import numeral from 'numeral'
import _ from 'lodash'

type Props = {}

const OrdersPage: FC<Props> = (props) => {
    const navigate = useNavigate()
    const currentUser = useReactiveVar(currentUserVar);
    const searchParams = useAppSearch()
    const { offset, limit, setPage } = usePagination()
    const { orders, count, refetch, loading } = useCarts({
        variables: {
            filter: {
                supplier_id: {
                    eq: currentUser.id
                },
                cart_number:searchParams?.search? {
                    like:`${searchParams.search}`
                  }:undefined
            },
            pagination: {
                limit,
                offset
            },
        },
        notifyOnNetworkStatusChange: true,
    })
    return (
        <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
            <OfficeHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={'orders'}
                        hasSearch
                        renderLoader={() => <TableLoader items={[1, 2, 2, 2, 2, 2,1]} actionsCount={1} showAction />}
                        columns={[
                            {
                                name: "Cart Ref#",
                                accessor: "cart_number",
                                render: (item: GetCarts_rows) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                {item.cart_number}
                                            </p>
                                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {moment(item?.createdAt).format("Do MMM, YYYY")}
                                            </p>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Seller",
                                accessor: "",
                                render: (item: GetCarts_rows) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                {item.reseller?.name ?? "N/A"}
                                            </p>
                                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {item.reseller?.username ?? "N/A"}
                                            </p>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Customer",
                                accessor: "",
                                render: (item: GetCarts_rows) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                {item.customer?.name}
                                            </p>
                                            {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {item.customer?.phone}
                                            </p> */}
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Payment",
                                accessor: "",
                                render: (item: GetCarts_rows) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                {item.payment_type?.replace(/_/g, " ")}
                                            </p>
                                            <span className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {item.payment_status}
                                            </span>
                                            <span className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {item.payment?.amount ?? 0.0}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Delivery Details",
                                accessor: "",
                                render: (item: GetCarts_rows) => {
                                    return (
                                        <div className="flex flex-col">
                                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                {item.delivery?.location}
                                            </p>
                                            <span className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                {item.payment_status === "PICKUP"
                                                    ? "0.00"
                                                    : numeral(item.delivery_amount).format("0,0.00")}
                                            </span>
                                            <div className="flex flex-col space-y-1">
                                                <span className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                    {/* {item.address?.city} */}
                                                </span>
                                                <span className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                                    {/* {item.address?.area} */}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Status",
                                accessor: "status",
                                render: (item: GetCarts_rows) => <span className='text-xs rounded-full bg-slate-100 text-center px-2 py-1 '>
                                    {
                                        _.isEmpty(_.last(item?.orderItems)?.status)
                                        ? "UNKNOWN"
                                        : _.last(item?.orderItems)?.status?.toUpperCase()
                                    }
                                    </span>
                            },
                            {
                                name: "Total",
                                accessor: "order_total",
                                render: (item: GetCarts_rows) => <div>{numeral(item?.order_total).format("#,#.##")}</div>
                            },
                            {
                                name: "Action",
                                accessor: "",
                                render: (data: GetCarts_rows) => <div>
                                    <ActionButton tooltip='View Order' action="view" onClick={() => navigate({
                                        to: './' + data.id,

                                    })} />
                                </div>
                            }
                        ]}

                        data={orders}
                        refetch={refetch}
                        loading={loading}
                        pagination={() => <Paginator
                            offset={offset}
                            limit={limit}
                            totalItems={count}
                            currentSize={orders.length}
                            setOffset={setPage}
                        />}
                    />
                </div>
            </div>
        </main>
    )
}

export default OrdersPage