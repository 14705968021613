import { gql } from '@apollo/client';

export const getDiscounts = gql`
  query GetDiscounts($filter: DiscountFilter, $pagination: Pagination) {
    getDiscounts(filter: $filter, pagination: $pagination) {
      id
      type
      valid_country
      code
      percentage
      amount
      description
      is_draft
      start_date
      expiry_date
      redemptions_limit
      product_condition
      products
      fixed_amount_allocation
      created_at
      updated_at
      created_by
      user_id
      has_expired
      number_of_times_used
      locations
    }
  }
`;

export const getDiscount = gql`
  query GetDiscount($filter: DiscountFilter!) {
    getDiscount(filter: $filter) {
      id
      type
      valid_country
      code
      percentage
      amount
      description
      is_draft
      start_date
      expiry_date
      redemptions_limit
      product_condition
      products
      fixed_amount_allocation
      created_at
      updated_at
      created_by
      user_id
      has_expired
      number_of_times_used
      locations
      locations_tagname
    }
  }
`;

export const getDiscountProducts = gql`
  query GetDiscountProductConditions(
    $filter: DiscountProductConditionFilter
    $pagination: Pagination
  ) {
    getDiscountProductConditions(filter: $filter, pagination: $pagination) {
      id
      product_sku
      discount_id
      created_at
      updated_at
      product {
        sku
        id
        title
        limited_stock
        qty
        main_stock
        transit_stock
        price
        profit_added
        currency
        country
        description
        extra_information
        combination_string
        published
        inhouse
        attended_to
        verified
        cost_price
        weight
        avatar
        tags
        shop_id
        resell
        uuid
        likesCount
        savesCount
        sharesCount
        supplier_id
        created_at
        overalReview
      }
    }
  }
`;
export const getProductLength = gql`
  query GetProductsLength($filter: productFilter) {
    getProductsLength(filter: $filter)
  }
`;

export const getSku = gql`
  query GetSku {
    getSKU
  }
`;

export const getCategoriesLength = gql`
  query GetCategoriesLength($filter: ProductCategoryFilter) {
    getProductCategoriesLength(filter: $filter)
  }
`;

export const get_featured_categories = gql`
  query FeaturedCategories($filter: FeaturedCategoriesFilter) {
    featuredCategories(filter: $filter) {
      id
      color
      banners
      category {
        id
        name
        description
        avatar
        children {
          id
          name
          description
          avatar
        }
      }
    }
  }
`;

export const getProducts = gql`
  query GetProducts(
    $getProductsFilter: productFilter
    $getProductsPagination: Pagination
    $condition: Condition
  ) {
    getProducts(
      filter: $getProductsFilter
      pagination: $getProductsPagination
      condition: $condition
    ) {
      id
      title
      overalReview
      sku
      qty
      price
      status
      supplier_cost
      description
      instantDelivery
      extra_information
      published
      avatar
      digitalDetails
      productType
      categories {
        id
        name
        description
      }
      limited_stock
      currency
      verified
      inhouse
      cost_price
      weight
      tags
      media {
        avatar
        id
      }
      combination_string
      comments {
        id
        staff_number
        message
        created_at
        updated_at
      }
      variations {
        id
        variation_name
        variation_options {
          id
          variation_value
        }
      }
      distributionChannels
      main_stock
      transit_stock
      profit_added
      country
      attended_to
      shop_id
      resell
      uuid
      likesCount
      savesCount
      sharesCount
      supplier_id
      supplier {
        id
        name
        email
        phone
        country
        has_verified_phonenumber
        has_verified_email
        setup_complete
        generic_name
        createdAt
        deletedAt
        agreement_terms
        business_details {
          business_name
        }
      }
    }
    getProductsLength(filter: $getProductsFilter, condition: $condition)
  }
`;

export const getProduct = gql`
  query GetProduct($filter: productFilter) {
    getProduct(filter: $filter) {
      id
      title
      sku
      limited_stock
      status
      qty
      price
      supplier_cost
      product_origin
      currency
      distributionChannels
      description
      digitalDetails
      combination_string
      instantDelivery
      productType
      extra_information
      published
      inhouse
      attended_to
      verified
      cost_price
      weight
      country
      avatar
      tags
      resell
      uuid
      likesCount
      savesCount
      sharesCount
      categories {
        id
        name
        description
        avatar
      }
      media {
        id
        avatar
      }
      variations {
        id
        variation_name
        variation_options {
          id
          variation_value
        }
      }
      comments {
        id
        staff_number
        message
        created_at
        updated_at
      }
      combinations {
        id
        sku
        price
        available_stock
        combination_string
      }
      shop_id
      minimumOrderQuantity
      main_stock
      transit_stock
    }
  }
`;
export const getProductTitle = gql`
  query GetProductTitle($filter: productFilter) {
    getProduct(filter: $filter) {
      id
      title
      sku
    }
  }
`;

export const getCategories = gql`
  query GetProductCategories(
    $filter: ProductCategoryFilter
    $pagination: Pagination
  ) {
    getProductCategoriesLength(filter: $filter)
    getProductCategories(filter: $filter, pagination: $pagination) {
      description
      id
      name
      avatar
    }
  }
`;

export const getCategory = gql`
  query GetProductCategory($filter: ProductCategoryFilter) {
    getProductCategory(filter: $filter) {
      id
      name
      description
      isActive
      avatar
      parent
    }
  }
`;

export const loadShoifyProducts = gql`
  query LoadShopifyProducts($integrationId: ID!) {
    loadShopifyProducts(integrationId: $integrationId) {
      id
      title
      handle
      body_html
      status
      images {
        created_at
        height
        id
        position
        product_id
        src
        updated_at
        variant_ids
        width
      }
    }
  }
`;

export const getProductTemplates = gql`
    query GetProductTemplates($filter: TemplateFilter, $pagination: Pagination) {
        getProductTemplates(filter: $filter, pagination: $pagination) {
            id
            name
            description
            body {
                label
                type
                descrition
                required
                dependsOn
                options {
                    value
                    label
                }
            }
        }
    }
`;

export const getProductTemplate = gql`
    query GetProductTemplate($filter: TemplateFilter!) {
        getProductTemplate(filter: $filter) {
            id
            name
            description
            body {
                label
                type
                descrition
                required
                dependsOn
                options {
                    value
                    label
                }
            }
        }
  }`


export const getProductHistory = gql`
  query GetProductHistory(
    $filter: ProductHistoryFilter!
    $pagination: Pagination!
  ) {
    getProductHistory(filter: $filter, pagination: $pagination) {
      id
      product_id
      action
      actor
      notes
      oldSnapshot
      newSnapshot
      createdAt
      updatedAt
    }
  }
`;
