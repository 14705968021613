import { useAppNaviagte, useAppURL } from '_graphql/cache/auth';
import {
  Avatar,
  List,
  OfficeButton,
  OfficeHeader,
  Show,
  toast,
} from 'components';
import _ from 'lodash';
import SectionLayout from 'pages/create-product/components/SectionLayout';
import { useProduct } from 'pages/products/hooks/use-products';
import { FC } from 'react';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { dispatchAction, useUrlState, wrapImage } from 'utils';
import UpdateProductDistribution from './update-product-distribution';
import UpdateProductInformation from './update-product-information';
import UpdateProductInventory from './update-product-inventory';
import UpdateProductMedia from './update-product-media';
import UpdateProductCategoryAndTag from './update-product-category-and-tag';
import { ProductStatus, ProductType } from '_graphql/__generated__/globalTypes';
import RejectReasonModal from 'pages/products/modal/RejectReasonModal';
import Toggle from 'pages/create-product/components/Toggle';
import { useUpdateProductInventory } from './hooks/use-update-inventory';

type Props = {};

const ViewProductPage: FC<Props> = (props) => {
  const { params } = useAppURL();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState('modal');
  const [id, setId] = useUrlState('id');
  const navigate = useAppNaviagte();

  const { loading: updating, updateProductInventory } =
    useUpdateProductInventory();
  const { product, loading, refetch } = useProduct({
    variables: {
      filter: {
        id: {
          eq: params?.product_id,
        },
      },
    },
  });
  const handleStockUpdate = async () => {
    await updateProductInventory({
      variables: {
        input: {
          product_id: product?.id || '',
          limited_stock: true,
          qty: 0,
          published: false,
          verified: false,
          personnel: 'Supplier',
          reason: 'N/A',
          status: ProductStatus.PENDING,
        },
      },
    })
      .then(({ data }) => {
        if (data?.updateProductInventory) {
          toast('Product Updated Successfully', 'success');
          navigate({
            search(prev) {
              return { ...prev, modal: undefined, id: undefined };
            },
          });
        } else {
          toast('Something went wrong', 'error');
        }
      })
      .catch((err) => {
        toast(err.message, 'error');
      });
  };
  return (
    <main className="flex-1 flex flex-col  h-screen bg-gray-100  relative">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={refetch}>Refresh</OfficeButton>
        )}
      />

      <div className="grid sm:grid-cols-3 mb-5 overflow-y-auto">
        <div className="sm:col-span-2">
          <SectionLayout
            loading={loading}
            freeFlow
            title={'Product Information'}
            renderActions={() => (
              <button
                className="text-primary-400 text-sm "
                type="button"
                onClick={dispatchAction(
                  product?.id || '',
                  'update_product_information',
                  navigate
                )}
              >
                Edit
              </button>
            )}
          >
            <div className="space-y-6 divide-y divide-gray-200  w-full">
              <div>
                <div className="grid md:grid-cols-4 gap-6">
                  <div className="md:row-span-3">
                    <Avatar
                      size="2xl"
                      src={product?.avatar}
                      alt={product?.title || ''}
                      fitStyle="contain"
                    />
                    <div className="w-full mt-2 flex flex-col justify-center items-center">
                      <button
                        disabled={product?.status !== ProductStatus.REJECTED}
                        onClick={() => {
                          setModal('reject_reason');
                          setId(product?.id);
                        }}
                        className={`text-center w-full rounded  text-[11px] py-1 pr-3 ${product?.status === ProductStatus.ACCEPTED ? 'bg-green-200 text-green-800' : product?.status === ProductStatus.REJECTED ? 'bg-red-200 text-red-800 cursor-pointer hover:bg-red-400' : 'bg-gray-200 text-gray-800'}`}
                      >
                        {product?.status}
                      </button>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {product?.title?.toUpperCase() || 'N/A'}
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        SKU (Stock Keeping Unit)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.sku || 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    {product?.productType === ProductType.Physical ? (
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Weight
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {product?.weight || 'N/A'} KG
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Type
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {_.startCase(product?.digitalDetails?.type) ||
                            'Unknown'}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Minimum Order Quantity
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.minimumOrderQuantity || 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Selling Price
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.currency} {product?.cost_price || 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-6 mt-2">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Cost Per Item
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {product?.currency} {product?.supplier_cost || 'N/A'}
                      </div>
                    </div>
                  </div>
                  {product?.distributionChannels?.includes('TendoNetwork') && (
                    <div className=" gap-6 mt-2">
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Tendo Price
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {product?.currency} {product?.price}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SectionLayout>

          <Show if={product?.productType === ProductType.Digital}>
            <SectionLayout
              loading={loading}
              freeFlow
              title={'Digital Product Information'}
            >
              <div className="grid lg:grid-cols-2 gap-6">
                {_.map(
                  Object.entries(product?.digitalDetails || {}),
                  ([key, value]) => {
                    return (
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          {_.startCase(key)}
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {value?.toString().startsWith('http') ? (
                            <a
                              href={value?.toString()}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {value?.toString()}
                            </a>
                          ) : (
                            value?.toString() || 'N/A'
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </SectionLayout>
          </Show>
          <SectionLayout
            freeFlow
            loading={loading}
            title={'Product Media'}
            renderActions={() => (
              <button
                className="text-primary-400 text-sm "
                type="button"
                onClick={dispatchAction(
                  product?.id || '',
                  'update_product_media',
                  navigate
                )}
              >
                Edit
              </button>
            )}
          >
            <div className="grid grid-cols-2 sm:grid-cols-4  gap-2">
              <List
                data={product?.media || []}
                renderItem={(img) =>
                  wrapImage(
                    <img
                      src={img?.avatar || ''}
                      alt="product_image"
                      key={img?.id}
                      className="w-36 h-36 rounded object-contain   flex-shrink-0 flex-grow-0 "
                    />
                  )
                }
              />
            </div>
          </SectionLayout>
          <SectionLayout
            freeFlow
            loading={loading}
            title={'Product Description'}
          >
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: product?.description || '' }}
            />
          </SectionLayout>
          {product?.extra_information && (
            <SectionLayout
              freeFlow
              loading={loading}
              title={'Extra Information'}
            >
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: product?.extra_information || '',
                }}
              />
            </SectionLayout>
          )}
        </div>
        <div>
          <div className="sticky top-6">
            <Show if={product?.productType === ProductType.Physical}>
              <SectionLayout
                loading={loading}
                title={'Inventory Management'}
                freeFlow
                renderActions={() => (
                  <button
                    className="text-primary-400 text-sm "
                    type="button"
                    onClick={dispatchAction(
                      product?.id || '',
                      'update_product_inventory',
                      navigate
                    )}
                  >
                    Edit
                  </button>
                )}
              >
                <div className=" w-full space-y-3">
                  <div className="flex justify-between items-center gap-2 w-full">
                    <span> Limited Stock : </span>
                    <span className="font-bold">
                      {product?.limited_stock ? 'Yes' : 'No'}
                    </span>
                  </div>
                  {product?.limited_stock && (
                    <>
                      <div className="flex justify-between items-center gap-2 ">
                        <span> Main Stock : </span>
                        <span className="font-bold">{product?.main_stock}</span>
                      </div>
                      <div className="flex justify-between items-center gap-2 ">
                        <span> Items In Stock : </span>
                        <span className="font-bold">{product?.qty}</span>
                      </div>
                      <div className="flex justify-between items-center gap-2 ">
                        <span> Transit Stock : </span>
                        <span className="font-bold">
                          {product?.transit_stock}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </SectionLayout>
            </Show>

            <SectionLayout
              loading={loading}
              title={'Category And Tags'}
              freeFlow
              renderActions={() => (
                <button
                  className="text-primary-400 text-sm "
                  type="button"
                  onClick={dispatchAction(
                    product?.id || '',
                    'update_product_category',
                    navigate
                  )}
                >
                  Edit
                </button>
              )}
            >
              <div>
                <div className="flex items-start justify-start gap-2">
                  <div className="">Categories :</div>
                  <div className="grid grid-cols-3 gap-3">
                    <List
                      data={product?.categories || []}
                      renderItem={(category) => (
                        <span
                          key={category?.id}
                          className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                        >
                          {category?.name}
                        </span>
                      )}
                    />
                  </div>
                </div>
                <hr className="my-5" />
                <div className="flex items-start justify-start gap-2">
                  <div className="">Tags :</div>
                  <div className="grid grid-cols-2 gap-3 ">
                    <List
                      data={product?.tags || []}
                      renderItem={(tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                        >
                          {tag}
                        </span>
                      )}
                    />
                  </div>
                </div>
              </div>
            </SectionLayout>
            <SectionLayout
              loading={loading}
              title={'Distribution Channels'}
              freeFlow
              renderActions={() => (
                <button
                  className="text-primary-400 text-sm "
                  type="button"
                  onClick={dispatchAction(
                    product?.id || '',
                    'update_product_distribution',
                    navigate
                  )}
                >
                  Edit
                </button>
              )}
            >
              <div className="flex flex-wrap">
                <List
                  data={product?.distributionChannels || []}
                  renderItem={(tag) => (
                    <span
                      key={tag}
                      className="px-2 py-1 mr-2 bg-gray-100 rounded text-sm whitespace-nowrap"
                    >
                      {_.startCase(tag)}
                    </span>
                  )}
                />
              </div>
            </SectionLayout>
            <SectionLayout title="Product State" loading={loading} freeFlow>
              <div className="w-full">
                <Show if={product?.productType === ProductType.Physical}>
                  <div className="border-b pb-2">
                    <Toggle
                      label="Visibility"
                      description={
                        'Products are set to out of stock, unpublished and unverified when turned off.'
                      }
                      value={
                        ((product?.qty || 0) > 0 && product?.limited_stock) ||
                        !product?.limited_stock ||
                        false
                      }
                      setValue={async (value) => {
                        if (
                          ((product?.qty || 0) > 0 && product?.limited_stock) ||
                          !product?.limited_stock ||
                          false
                        ) {
                          handleStockUpdate();
                        } else {
                          setModal('update_product_inventory');
                          setId(product?.id);
                        }
                      }}
                      display={true ? 'Live' : 'Draft'}
                    />
                  </div>
                </Show>
                <div className="w-full flex justify-between items-center border-b  py-2">
                  <span className="font-semibold text-sm ">Published</span>
                  <span
                    className={`text-xs  px-2 py-1 rounded font-semibold ${product?.published ? 'text-green-600 bg-green-100' : 'text-red-600 bg-red-100'}`}
                  >
                    {product?.published ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className="w-full flex justify-between items-center py-2 ">
                  <span className="font-semibold text-sm ">Verified</span>
                  <span
                    className={`text-xs  px-2 py-1 rounded font-semibold ${product?.verified ? 'text-green-600 bg-green-100' : 'text-red-600 bg-red-100'}`}
                  >
                    {product?.verified ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
            </SectionLayout>
          </div>
        </div>
      </div>
      {/* Modals */}
      {!searchParams?.id?.length && (
        <UpdateProductCategoryAndTag
          open={modal === 'update_product_category'}
          setOpen={(val: boolean) =>
            setModal(val ? 'update_product_category' : undefined)
          }
        />
      )}
      {!searchParams?.id?.length && (
        <UpdateProductDistribution
          open={modal === 'update_product_distribution'}
          setOpen={(val: boolean) =>
            setModal(val ? 'update_product_distribution' : undefined)
          }
        />
      )}
      {!searchParams?.id?.length && (
        <UpdateProductInformation
          open={modal === 'update_product_information'}
          setOpen={(val: boolean) =>
            setModal(val ? 'update_product_information' : undefined)
          }
        />
      )}
      {!searchParams?.id?.length && (
        <UpdateProductInventory
          open={modal === 'update_product_inventory'}
          setOpen={(val: boolean) =>
            setModal(val ? 'update_product_inventory' : undefined)
          }
        />
      )}

      {!searchParams?.id?.length && (
        <UpdateProductMedia
          open={modal === 'update_product_media'}
          setOpen={(val: boolean) =>
            setModal(val ? 'update_product_media' : undefined)
          }
        />
      )}
      {modal === 'reject_reason' && (
        <RejectReasonModal
          open={modal === 'reject_reason'}
          setOpen={() =>
            navigate({
              search(prev) {
                return { ...prev, modal: undefined, id: undefined };
              },
            })
          }
        />
      )}
    </main>
  );
};

export default ViewProductPage;
