import { gql } from "@apollo/client";

export const LOGIN = gql`
mutation SupplierSignIn($emailOrPhone: String!, $password: String!, $type:String) {
  supplierSignIn(emailOrPhone: $emailOrPhone, password: $password, type: $type) {
    token
    supplier {
      business_details {
        business_name
      }
      id
      name
      email
      supplier_type
      phone
      country
      has_verified_phonenumber
      has_verified_email
      setup_complete
      generic_name
      device_token
      agreement_terms
      social_platform
      social_platform_token
      createdAt
      deletedAt
      permissions
      avatar
      setupConfig {
        hasCategories
        hasTerms
        hasPaymentMethod
      }
      payment_details {
        id
        supplier_id
        account_name
        account_number
        provider
        method
      }
    }
  }
}
`;

export const forgotPassword = gql`
  mutation RequestSupplierOTP($input: requestOTPInput) {
    requestSupplierOTP(input: $input) {
      token
    }
  }
`;

export const verifyOTP = gql`
  mutation VerifySupplierOTP($input: verifySupplierOTPInput) {
    verifySupplierOTP(input: $input)
  }
`;

export const createSupplier = gql`
  mutation CreateSupplier($input: SupplierInput) {
    createSupplier(input: $input) {
      created
      verification_token
    }
  }
`;

export const changePassword = gql`
  mutation ChangeSupplierPassword($input: SupplierPasswordChangeInput) {
    changeSupplierPassword(input: $input)
  }
`;
export const changeSupplierPhone = gql`
mutation RequestChangeSupplierPhone($input: requestChangeSupplierPhoneInput) {
  requestChangeSupplierPhone(input: $input)
}
`;

export const registerReseller = gql`
  mutation AccountRegister($input: accountRegisterInput) {
    accountRegister(input: $input) {
      token
      user {
        id
      }
    }
  }
`;

export const joinSupplierNetwork = gql`
  mutation JoinSupplierResellerNetwork(
    $supplierId: ID!
    $resellerId: ID!
    $termsId: ID!
  ) {
    joinSupplierResellerNetwork(
      supplier_id: $supplierId
      reseller_id: $resellerId
      terms_id: $termsId
    )
  }
`;


export const addSupplierBusinessDetails = gql`
mutation AddSupplierBusinessDetails($input: SupplierBusinessInput) {
  addSupplierBusinessDetails(input: $input)
}`;