import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { toast } from 'components';
import { Fragment } from 'react';
import { useShopifyShop } from 'pages/shopify/hooks/useShopifyShop';
import { useCurrentUser } from '_graphql/cache/auth';
import { LOAD_PRODUCTS_TO_SHOPIFY } from '_graphql/mutation/products';
import { useMutation } from '@apollo/client';
import { useProduct } from './hooks/use-products';

type Props = {
  selectedProductIds: string[];
  setSelectedProductIds: React.Dispatch<React.SetStateAction<string[]>>;
  loadingProductsIds: string[];
  setLoadingProductIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const LoadProductButton = ({
  selectedProductIds,
  setSelectedProductIds,
  loadingProductsIds,
  setLoadingProductIds,
}: Props) => {
  const currentUser = useCurrentUser();

  const { shopData } = useShopifyShop({
    input: {
      filter: {
        supplier_id: {
          eq: currentUser?.id,
        },
      },
    },
  });

  const [loadProductsToShopify] = useMutation(LOAD_PRODUCTS_TO_SHOPIFY);
  const { refetch } = useProduct({ variables: { filter: {} } });

  const getProductDetails = async (id: string) => {
    const { data } = await refetch({ filter: { id: { eq: id } } });
    const productDetails = data.getProduct;

    return productDetails;
  };

  const handleLoadShopifyProducts = async () => {
    try {
      setLoadingProductIds(selectedProductIds);
      const input = {
        integration_id: shopData?.id,

        products: await Promise.all(
          selectedProductIds.map(async (productId) => {
            const productDetails = await getProductDetails(productId);

            if (productDetails) {
              const {
                uuid,
                qty,
                title,
                sku,
                product_origin,
                description,
                media,
              } = productDetails;

              return {
                product_id: productId,
                uuid,
                qty,
                title,
                sku,
                product_origin,
                description,
                media: media?.map((el) => el?.avatar),
              };
            } else {
              return null;
            }
          })
        ),
      };
      const { data } = await loadProductsToShopify({ variables: { input } });

      if (data?.loadProductsToShopify) {
        toast('Products is being loaded to shopify', 'success');
        //   setSelectedProductIds([]);
        //   setLoadingProductIds((prevLoadingIds) =>
        //   prevLoadingIds.filter(id => !selectedProductIds.includes(id))
        // );
      } else {
        toast('Failed to load products to shopify', 'error');
      }
    } catch (error) {
      setLoadingProductIds((prevLoadingIds) =>
        prevLoadingIds.filter((id) => !selectedProductIds.includes(id))
      );
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          as={'div'}
          className="inline-flex items-center rounded-md border border-gray-200  disabled:cursor-not-allowed px-3 py-2 text-sm leading-4  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 bg-gray-100 focus:ring-primary-500 hover:bg-primary-700 hover:text-white text-primary-500"
        >
          {/* <svg
            className="-ml-1 mr-2 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg> */}
          <ShoppingBagIcon className="ml-2 mr-2  h-6 w-6 text-secondary-200 hover:text-secondary-100" />
          <div className="text-white text-center text-xs flex items-center justify-center  font-bold bg-primary-500 rounded-full w-4 h-4 relative right-4 text-bold bottom-1">
            {selectedProductIds.length}
          </div>
          Load Product
          <ChevronDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-secondary-200 hover:text-secondary-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <span
                  //   onClick={() => goToCreateProduct(true)}
                  className={`${
                    active ? 'bg-primary-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  To Supplier Storefront
                </span>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={handleLoadShopifyProducts}
                  className={`${
                    active ? 'bg-primary-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  To shopify
                </span>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LoadProductButton;
