import { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { setAuth, setCountry, useAppSearch } from '_graphql/cache/auth';
import { Link, useNavigate } from 'react-location';
import * as Yup from 'yup';
import { TextInput } from 'components/core';
import { useFormik } from 'formik';
import { LOGIN } from '_graphql/mutation/auth';
import {
  SupplierSignIn,
  SupplierSignInVariables,
} from '_graphql/mutation/__generated__/SupplierSignIn';
import { useApp } from 'hooks';
import { SegmentedControl } from '@mantine/core';
// import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { AddDeviceToken } from '_graphql/mutation/notifications';
import {
  AddSupplierDeviceToken,
  AddSupplierDeviceTokenVariables,
} from '_graphql/mutation/__generated__/AddSupplierDeviceToken';
import { generateToken } from 'notifications/firebase';

const LoginPage: FC = () => {
  const search = useAppSearch();
  const navigate = useNavigate();
  const { name, logo } = useApp();
  const [login, { loading }] = useMutation<
    SupplierSignIn,
    SupplierSignInVariables
  >(LOGIN);
  const [type, setType] = useState('email');
  // const [showPassword, setShowPassword] = useState(false);
  const [add] = useMutation<
    AddSupplierDeviceToken,
    AddSupplierDeviceTokenVariables
  >(AddDeviceToken);

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: search?.email || '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Email or phone is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      await login({
        variables: {
          emailOrPhone: values.username,
          password: values.password,
          type,
        },
      }).then(({ data }) => {
        if (data?.supplierSignIn) {
          if (data.supplierSignIn?.supplier) {
            generateToken().then((token) => {
              if (token) {
                add({
                  variables: {
                    input: {
                      deviceToken: token,
                      id: data?.supplierSignIn?.supplier?.id || '',
                    },
                  },
                });
              }
            });
            setCountry(data?.supplierSignIn.supplier.country || '');
            setAuth({
              user: data?.supplierSignIn?.supplier,
              token: data.supplierSignIn.token ?? '',
            });
          } else {
            return navigate({
              replace: true,
              to: search?.redirect ?? '/',
            });
          }
        }
      });
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 ">
      <div>
        <div className="flex items-start flex-col">
          <div className="w-full justify-start py-4 flex align-middle flex-shrink-0">
            <img
              className="h-16 w-16 rounded-full"
              src={logo}
              alt={`${name} Supplier's Dashboard`}
            />
          </div>
          <div className="">
            <span className="text-lg text-primary-600 font-mono">
              {name} Supplier's Dashboard
            </span>
          </div>
        </div>
        <SegmentedControl
          value={type}
          onChange={setType}
          className="mt-5"
          fullWidth
          data={[
            { label: 'Email', value: 'email' },
            { label: 'Phone', value: 'phone' },
          ]}
        />

        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Enter your credentials below
        </p>
        <p className="mt-2 text-sm leading-6 text-gray-500">
          Not a supplier yet?{' '}
          <Link
            to="/sign-up"
            className="font-semibold text-primary-500 hover:text-primary-500"
          >
            Sign up here
          </Link>
        </p>
        {search?.from === 'reset_success' && (
          <div className="bg-teal-100 px-2 rounded-md py-1 mt-3">
            <p className="text-sm text-teal-600">
              Password Changed SuccessFully. Login to access the {name}{' '}
              Supplier's Dashboard
            </p>
          </div>
        )}
        {search?.from === 'registration-otp-verify' && (
          <div className="bg-teal-100 px-2 rounded-md py-1 mt-3">
            <p className="text-sm text-teal-600">
              Account Created Successfully. Login to access the {name}{' '}
              Supplier's Dashboard
            </p>
          </div>
        )}
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label={type === 'email' ? 'Email address' : 'Phone number'}
                // type='email'
                type={type === 'email' ? 'email' : 'text'}
                placeholder="e.g. ebaidoo79@gmail.com"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
              {/* <div className='w-full flex justify-end items-center'>
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='text-xs my-1 font-medium  text-gray-500 hover:text-primary-500'>
                  {showPassword ? (
                    <span className='flex justify-center items-center gap-1'>
                      Hide Password
                      <EyeSlashIcon className='h-4 w-4' />
                    </span>
                  ) : (
                    <span className='flex justify-center items-center gap-1'>
                      Show Password <EyeIcon className='h-4 w-4' />
                    </span>
                  )}
                </button>
              </div> */}
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm text-primary-500">
                <Link to="/forgot-password">Forgot your password?</Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? 'Signing in...' : 'Sign in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
