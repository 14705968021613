import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MediaServices from '../services/media.service';

export type IFile = {
  id: number | undefined;
  loading: boolean;
  error: string;
  imgLink: string;
  imageName: string;
  imagePath: string;
};

type UploadProps = {
  multiple?: boolean;
  onUploaded?: (images: IFile[]) => void;
};

const useUploadMedia = (title?: string, props?: UploadProps) => {
  const [uploadingMedia, setUploadingMedia] = useState<IFile[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files

      acceptedFiles.forEach(async (file: File) => {
        setUploadingMedia((prevState) =>
          prevState.concat({
            id: undefined,
            loading: true,
            error: '',
            imgLink: '',
            imageName: file.name,
            imagePath: '',
          })
        );

        const formData = new FormData();
        formData.append('media', file);
        formData.append(
          'folder',
          `tendo/${title ? title.replace(' ', '-') : 'product-media'}`
        );
        formData.append('origin', 'supplier-dashboard');

        MediaServices.uploadImage(formData)
          .then((res) => {
            setUploadingMedia((prev) => {
              const index = prev.findIndex((el) => el.imageName === file.name);

              const newImages = R.adjust(
                index,
                (item) => ({
                  ...item,
                  loading: false,
                  error: '',
                  imgLink: res.data.data[0].imageLink,
                  imagePath: res.data.data[0].path,
                  id: res.data.data[0].id,
                }),
                prev
              );
              props?.onUploaded && props?.onUploaded(newImages);
              return newImages;
            });
          })
          .catch((err) => {
            setUploadingMedia((prevState) =>
              prevState.map((item) =>
                item.imageName === file.name
                  ? {
                      ...item,
                      loading: false,
                      error: err.message,
                    }
                  : item
              )
            );
          });
      });
    },
    [title]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/webp': ['.webp'],
    },
    multiple: props?.multiple,
  });

  const deleteFile = (index: number) => {
    const temp = uploadingMedia[index];
    // const file = {
    //   id: temp.id,
    //   imageLink: temp.imgLink,
    //   name: temp.imageName,
    //   path: temp.imagePath,
    // };
    if (temp.id) {
      MediaServices.deleteMedia(temp.imagePath)
        .then(() => {
          setUploadingMedia((prevState) =>
            prevState.filter((item, i) => i !== index)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUploadingMedia((prevState) =>
        prevState.filter((item, i) => i !== index)
      );
    }
  };

  const onUrlInputSubmit = (e: Record<string, any>, callback: Function) => {
    const mediaUrlInputArray = Object.values(e).map((obj) => ({
      url: obj,
      folder: `tendo/${
        title ? title.replace(/\s+/g, '-').toLowerCase() : 'product-media'
      }`,
      origin: 'supplier-dashboard',
    }));

    mediaUrlInputArray.forEach(async (mediaUrlInput) => {
      setUploadingMedia((prevState) =>
        prevState.concat({
          id: undefined,
          loading: true,
          error: '',
          imgLink: '',
          imageName: mediaUrlInput.url,
          imagePath: '',
        })
      );
      MediaServices.uploadMediaUsingUrl(mediaUrlInput)
        .then((res) => {
          setUploadingMedia((prevState) => {
            const index = prevState.findIndex(
              (el) => el.imageName === mediaUrlInput.url
            );
            const newImages = R.adjust(
              index,
              (item) => ({
                ...item,
                loading: false,
                error: '',
                imgLink: res.data.data.imageLink,
                imagePath: res.data.data.path,
                id: res.data.data.id,
                imageName: res.data.data.originalname,
              }),
              prevState
            );

            props?.onUploaded && props?.onUploaded(newImages);
            return newImages;
          });
        })
        .catch((err) => {
          setUploadingMedia((prevState) =>
            prevState.map((item) =>
              item.imageName === mediaUrlInput.url
                ? {
                    ...item,
                    loading: false,
                    error: err.message,
                  }
                : item
            )
          );
        });
    });
    callback();
  };

  return {
    onUrlInputSubmit,
    uploadingMedia,
    getRootProps,
    getInputProps,
    isDragActive,
    deleteFile,
  };
};

export default useUploadMedia;
