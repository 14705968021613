import { Modal } from 'components';
import ProductView from './cards/view-product';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useProduct } from './hooks/use-products';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const ViewProductContainer = ({ open, setOpen }: Props) => {
  const navigate = useNavigate<LocationGenerics>();
  const seacrhParams = useSearch<LocationGenerics>();
  const { product, loading } = useProduct({
    variables: {
      filter: {
        id: seacrhParams?.id
          ? {
              eq: seacrhParams?.id,
            }
          : undefined,
      },
    },
  });

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      loading={loading}
      title="View Product"
      description="View Product Details"
    >
      <ProductView product={product} />
    </Modal>
  );
};

export default ViewProductContainer;
