import { ActionButton, OfficeButton, OfficeHeader, Paginator, TableComponent, TableLoader } from 'components'
import { FC } from 'react'
import { useTerms } from './hooks/use-terms'
import { usePagination } from 'hooks'
import { dispatchAction, useUrlState } from 'utils'
import CreateTermsContainer from './create'
import { GetTerm_getTerm } from '_graphql/queries/__generated__/GetTerm'
import { useNavigate, useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import ViewTermContainer from './view'
import UpdateTermContainer from './update'
import { useCurrentUser } from '_graphql/cache/auth'

type Props = {}

const TermsPage: FC<Props> = (props) => {
    const currentUser = useCurrentUser();
    const navigate = useNavigate<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const { offset, limit, setPage } = usePagination()
    const [modal, setModal] = useUrlState("modal");
    const { terms, count, loading, refetch } = useTerms({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            filter: {
                supplier_id: {
                    eq: currentUser?.id
                },
                name:searchParams?.search? {
                    like:`${searchParams.search}`
                  }:undefined
            },
            pagination: {
                limit,
                offset
            }
        }
    })
    return (
        <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
            <OfficeHeader renderActions={() => (
                <OfficeButton variant={'minimal'} onClick={() => setModal("create")}>
                    Create Term
                </OfficeButton>
            )} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={'terms'}
                        hasSearch
                        columns={[
                            {
                                name: "Name",
                                accessor: "name",
                            },
                            {
                                name: "Return Period",
                                accessor: "returnPeriod",
                            },
                            {
                                name: "Settlement Type",
                                accessor: "settlementType",
                            },
                            {
                                name: "Settlement Value",
                                accessor: "settlementValue",
                            },
                            {
                                name: "Settlement Period",
                                accessor: "settlementPeriod",
                            },
                            {
                                name: "Estimated Delivery Time",
                                accessor: "estimatedDeliveryTime",
                            },
                            {
                                name: "Estimated Delivery Time Unit",
                                accessor: "estimatedDeliveryTimeUnit",
                            },
                            {
                                name: "Ship To",
                                accessor: "shipTo",
                            },
                            {
                                name: "Settlement Currency",
                                accessor: "settlementCurrency",
                            },
                            {
                                name: "Actions",
                                accessor: "actions",
                                render: (term: GetTerm_getTerm) => (
                                    <div className="flex space-x-2">
                                        <ActionButton action={'view'} onClick={dispatchAction(term.id, "view", navigate)} />
                                        <ActionButton action={'update'} onClick={dispatchAction(term.id, "update", navigate)} />
                                    </div>
                                )
                            }
                        ]}

                        data={terms}
                        refetch={refetch}
                        loading={loading}
                        renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1, 1, 1, 1]} actionsCount={2} showAction />}
                        pagination={() => <Paginator
                            offset={offset}
                            limit={limit}
                            totalItems={count}
                            currentSize={terms.length}
                            setOffset={setPage}
                        />}
                    />
                </div>
            </div>
            <CreateTermsContainer open={modal === "create"} setOpen={(val: boolean) => setModal(val ? "create" : undefined)} />
            {!searchParams?.id?.length && (
                <>
                    <ViewTermContainer open={modal === "view"} setOpen={(val: boolean) => setModal(val ? "view" : undefined)} />
                    <UpdateTermContainer open={modal === "update"} setOpen={(val: boolean) => setModal(val ? "update" : undefined)} />
                </>
            )}
        </main>
    )
}

export default TermsPage