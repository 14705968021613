import { FC, useEffect } from 'react';
import { useNavigate, useSearch } from 'react-location';
import lodash from 'lodash';
import { LocationGenerics } from 'router/location';
import moment from 'moment';
import { CalendarHeader } from 'components';
import _ from 'lodash';
import { useCurrentUser } from '_graphql/cache/auth';
import SetupConfig from './setup-config';
import Dashboard from './dashboard';

const DashboardPage: FC = () => {
  const currentUser = useCurrentUser();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const allSet = lodash.every(
    _.values(
      _.omit(
        currentUser?.setupConfig,
        currentUser?.supplier_type !== 'BASIC'
          ? ['__typename']
          : ['__typename', 'hasTerms']
      )
    ),
    (item) => item === true
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.fromDate
          ? {
              fromDate: searchParams?.fromDate || moment().format('YYYY-MM-DD'),
            }
          : {}),
        ...(!old?.toDate
          ? { toDate: searchParams?.toDate || moment().format('YYYY-MM-DD') }
          : {}),
        ...(!old?.view ? { view: 'month' } : {}),
      }),
    });
  }, [navigate, searchParams?.fromDate, searchParams?.toDate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <CalendarHeader disable={!allSet} />
      {allSet ? <Dashboard /> : <SetupConfig />}
    </main>
  );
};

export default DashboardPage;
